
.home__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.home__designation{
    display: flex;
    margin: 0;
}

.home__messenger {
    margin: 0;
    font-family: "Barlow";
    font-weight: 100;
    font-size: 25px;
}

#messenger {
    margin-left: 5px;
    white-space: nowrap;
}

.home__contactlist {
    margin-top: 20px;
    display: flex;
}

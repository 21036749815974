.name__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.name__word {
    padding: 0;
    margin: 5px;
    font-family: Birch;
    font-weight: 500;
    font-size: 120px;
    color: black;
    z-index: 10;
    text-shadow: 0.02em 0.0em #940f08; /*LOOKS COOL NEED TO EXPERIMENT  */ 
}


.terrain-background {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    position: absolute;
    z-index: -1000;
  }
  
.name__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.name__word {
    padding: 0;
    margin: 5px;
    font-family: Birch;
    font-weight: 500;
    font-size: 120px;
    color: black;
    z-index: 10;
    text-shadow: 0.02em 0.0em #940f08; /*LOOKS COOL NEED TO EXPERIMENT  */ 
}



.home__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.home__designation{
    display: flex;
    margin: 0;
}

.home__messenger {
    margin: 0;
    font-family: "Barlow";
    font-weight: 100;
    font-size: 25px;
}

#messenger {
    margin-left: 5px;
    white-space: nowrap;
}

.home__contactlist {
    margin-top: 20px;
    display: flex;
}

.iconlink__icon {
    margin: 5px 20px;
    color: black;
    transition: all 0.5s ease;
    stroke:  #940f08;
    stroke-width: 0px;
}

.iconlink__icon:hover {
    stroke-width: 2%;
    color: white;
    cursor: pointer;
}

.iconlink__link {
    text-decoration: none;
}

.iconlink__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* No tooltips yet */

/*
.iconlink__tooltiptext {
    display: none;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    z-index: 50;
}

.iconlink__icon:hover .iconlink__tooltiptext {
    display: block;
}

.iconlink__tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; 
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
*/
@font-face {
  font-family: Birch;
  src: url(/static/media/BirchStd.0a17f0a5.otf);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: gray;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html, body, #root{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
}

.container {
  width: 100%;
  height: 100%;
  /* filter: invert(100%); */
}

canvas {
  z-index: 0;
  position: absolute;
}
